import type { FsmDefAxiosState } from "@do/shared-types";
import { Fade, LinearProgress } from "@mui/material";
import axios from "axios";
import { type Dispatch, memo, type SetStateAction, useLayoutEffect, useState } from "react";
import type { AnyEventObject } from "xstate";
import type { StepValues } from "./types";

export type AxiosStepProps = {
  onSetStepValue: Dispatch<SetStateAction<StepValues>>;
  options: FsmDefAxiosState["meta"]["options"];
  send: (event: AnyEventObject) => void;
  stateDef: FsmDefAxiosState;
  stateValue: string;
};

export const AxiosStep = memo<AxiosStepProps>(
  ({ onSetStepValue, options, send, stateDef, stateValue }) => {
    const [loading, setLoading] = useState(false);
    useLayoutEffect(() => {
      void (async () => {
        try {
          setLoading(true);
          const { data } = await axios(options);
          setLoading(false);
          onSetStepValue((stepValues: StepValues) => ({
            ...stepValues,
            [stateValue]: data,
          }));
          setTimeout(() => send({ type: "success" }), 0);
        } catch (e) {
          setLoading(false);
          send({ type: "error" });
        }
      })();
    }, [onSetStepValue, options, send, stateDef, stateValue]);
    return (
      <Fade in={loading} timeout={300}>
        <LinearProgress />
      </Fade>
    );
  },
  () => true
);
AxiosStep.displayName = "AxiosStep";
